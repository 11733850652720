import React from 'react';

const TimelineGraph = ({ companies, color, svgRef, title }) => {
  const svgWidth = 900;
  const svgHeight = 450;
  const paddingLeft = 120;
  const paddingRight = 10;
  const paddingTop = 20;
  const paddingBottom = 40;
  const timelineHeight = 20;
  const axisColor = "#DDDDDD";
  const labelColor = "#AAAAAA";

  const validCompanies = companies.filter(company =>
    company.startDate instanceof Date &&
    company.endDate instanceof Date &&
    !isNaN(company.startDate) &&
    !isNaN(company.endDate)
  );

  const minDate = validCompanies.length > 0
    ? new Date(Math.min(...validCompanies.map(c => c.startDate)))
    : new Date();
  const maxDate = validCompanies.length > 0
    ? new Date(Math.max(...validCompanies.map(c => c.endDate)))
    : new Date(minDate.getFullYear() + 1, 0, 1);

  // Adjust minDate and maxDate to the nearest years
  minDate.setMonth(0, 1);
  maxDate.setMonth(11, 31);

  const timeRange = maxDate.getTime() - minDate.getTime();

  const xScale = (date) => {
    if (!(date instanceof Date) || isNaN(date)) return paddingLeft;
    return ((date.getTime() - minDate.getTime()) / timeRange) * (svgWidth - paddingLeft - paddingRight) + paddingLeft;
  };

  const generateYearTicks = () => {
    const years = [];
    const startYear = minDate.getFullYear();
    const endYear = maxDate.getFullYear() + 1;
    for (let year = startYear; year <= endYear; year++) {
      years.push(new Date(year, 0, 1));
    }

    if (years.length < 3) {
      const months = [];
      for (let month = 0; month < 12; month++) {
        months.push(new Date(startYear, month, 1));
      }
      return months;
    }

    return years;
  };

  const wrapText = (text, width) => {
    const words = text.split(/\s+/).reverse();
    const lines = [];
    let line = [];
    let lineLength = 0;

    while (words.length > 0) {
      const word = words.pop();
      if (lineLength + word.length + (line.length > 0 ? 1 : 0) <= width) {
        line.push(word);
        lineLength += word.length + (line.length > 0 ? 1 : 0);
      } else {
        if (line.length > 0) {
          lines.push(line.join(' '));
        }
        line = [word];
        lineLength = word.length;
      }
    }
    lines.push(line.join(' '));
    return lines;
  };

  const lineHeight = 12;
  const maxLabelLines = 3; // Adjust this based on your needs
  const fixedCompanyHeight = Math.max(timelineHeight, maxLabelLines * lineHeight);
  const companySpacing = 2; // Spacing between company entries

  return (
    <svg ref={svgRef} width={svgWidth + 50} height={svgHeight}>
      {/* X-axis */}
      <text x="50%" y="20" textAnchor="middle" fontSize="24" fill="black">{title}</text>

      <line
        x1={paddingLeft}
        y1={paddingTop + 50}
        x2={svgWidth + paddingRight}
        y2={paddingTop + 50}
        stroke={axisColor}
      />
      {generateYearTicks().map((date, index) => {
        const year = date.getFullYear().toString();
        const wrappedYear = wrapText(year, 4);
        const lineHeight = 12;
        const totalHeight = wrappedYear.length * lineHeight;

        return (
          <g key={index}>
            <line
              x1={xScale(date)}
              y1={paddingTop + 45}
              x2={xScale(date)}
              y2={paddingTop + 50}
              stroke={axisColor}
            />
            {wrappedYear.map((line, lineIndex) => (
              <text
                key={lineIndex}
                x={xScale(date) - 25}
                y={paddingTop - totalHeight / 2 + lineIndex * lineHeight + 30}
                textAnchor="middle"
                fontSize="10"
                fill={labelColor}
                transform={`rotate(-45, ${xScale(date)}, ${paddingTop - totalHeight / 2 + lineIndex * lineHeight})`}
              >
                {line}
              </text>
            ))}
          </g>
        );
      })}

      {/* Y-axis */}
      <line
        x1={paddingLeft}
        y1={paddingTop + 50}
        x2={paddingLeft}
        y2={svgHeight - paddingBottom + 50}
        stroke={axisColor}
      />

      {/* Company timelines */}
      {validCompanies.map((company, index) => {
        const wrappedName = wrapText(company.name, 25);
        const yPosition = paddingTop + 70 + index * (fixedCompanyHeight + companySpacing);

        return (
          <g key={index}>
            <rect
              x={xScale(company.startDate)}
              y={yPosition + (fixedCompanyHeight - timelineHeight) / 2}
              width={Math.max(1, xScale(company.endDate) - xScale(company.startDate))}
              height={timelineHeight}
              fill={color}
            />
            {wrappedName.map((line, lineIndex) => (
              <text
                key={lineIndex}
                x={paddingLeft - 10}
                y={yPosition + fixedCompanyHeight / 2 + (lineIndex - (wrappedName.length - 1) / 2) * lineHeight}
                fontSize="10"
                textAnchor="end"
                fill={labelColor}
              >
                {line}
              </text>
            ))}
          </g>
        );
      })}
    </svg>
  );
};

export default TimelineGraph;
