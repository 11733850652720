// src/Pricing.js
import React, { useState } from 'react';
import { Check } from "lucide-react";
import './Pricing.css';
// import checkout from "./checkout";
// import { loadStripe } from '@stripe/stripe-js';
import WaitlistForm from './WaitlistForm';

const Pricing = ({ session, handleAuth, supabase }) => {
  const plans = [
    {
      title: "Free Tier",
      price: "$0",
      description: "Perfect for getting started",
      features: ["Unlimited Graphs", "3 Import credits per month", "Basic Customizations", "LinkedIn Import", "Resume Import", "PNG Export"],
    },
    {
      title: "Pay as You Go",
      price: "$1",
      description: "Ideal for individuals",
      features: ["Unlimited Graphs", "20 Import credits", "Basic Customizations", "LinkedIn Import", "Resume Import", "PNG Export"],
    },
  ];

  // const stripePromise = loadStripe(
  //   process.env.REACT_APP_STRIPE_PUBLIC_KEY
  // );

  // const [stripeError, setStripeError] = useState();
  // const [loading, setLoading] = useState();
  // const [paymentData, setPaymentData] = useState();
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);


  // const handleCheckout = async () => {
  //   setLoading(true);

  //   const stripe = await stripePromise;

  //   const { error } = await stripe.redirectToCheckout({
  //     lineItems: [
  //       {
  //         price: "price_1QG8d9Cdp4OssqD4rURA5fPE",
  //         quantity: 1,
  //       }
  //     ],
  //     mode: "payment",
  //     cancelUrl: `${window.location.origin}/cancel`,
  //     successUrl: `${window.location.origin}?session_id={CHECKOUT_SESSION_ID}`
  //   })

  //   if (!error) {
  //     console.log("Hello World")
  //   }
  // }

  const handleJoinWaitlist = () => {
    setShowWaitlistForm(true);
  };

  // const handleCheckout = async () => {
  //   setLoading(true);

  //   const stripe = await stripePromise;

  //   // Create a session on the server
  //   const response = await fetch('.', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //       priceId: "price_1QG8d9Cdp4OssqD4rURA5fPE",
  //     }),
  //   });

  //   const session = await response.json();

  //   // Redirect to the Stripe Checkout session
  //   const { error } = await stripe.redirectToCheckout({ sessionId: session.id });

  //   // Handle any errors
  //   if (error) {
  //     setStripeError(error.message);
  //   }
  // }

  return (
    <div className="pricing-container">
      {plans.map((plan) => (
        <div key={plan.title} className="pricing-tile">
          <h3 className="pricing-title">{plan.title}</h3>
          <div className="pricing-val">{plan.price}</div>
          {plan.title === "Free Tier" ? (
            <button
              className="free-tier-btn"
              onClick={() => {
                handleAuth();
              }}>
              Current Tier
            </button>
          ) : (
            <button
              className="purchase-btn"
              onClick={() => {
                // handleAuth();
                // if (session) {
                // handleCheckout();
                handleJoinWaitlist();
                // }
              }}>
              {/* Purchase */}
              Join the Waitlist
            </button>
            // <form action="/api/checkout" method="POST">
            //   <section>
            //     <button type="submit" role="link" className="purchase-btn">
            //       Checkout
            //     </button>
            //   </section>
            // </form>
          )}
          <p className="pricing-desc">{plan.description}</p>
          <ul className="mt-4 space-y-2">
            {plan.features.map((feature) => (
              <li key={feature} className="feature-item">
                <Check className="check-icon" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>

        </div>
      ))}

      {showWaitlistForm ? (
        <WaitlistForm
          supabase={supabase}
          onClose={() => setShowWaitlistForm(false)}
          onSuccess={() => setShowWaitlistForm(false)}
        />
      ) : (
        <></>
      )}

    </div>
  );
};

export default Pricing;