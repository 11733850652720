import React from 'react';

const VerticalTimeline = ({ companies, color, svgRef, title }) => {
  const svgWidth = 800;
  const maxSvgHeight = 450;
  const paddingTop = 70; // Increased padding top
  const paddingBottom = 50;
  const lineColor = "#CCCCCC";
  const textColor = "#333333";

  // Calculate the available space for data points
  const availableHeight = maxSvgHeight - paddingTop - paddingBottom;

  // Calculate the distance between data points
  const dataPointDistance = companies.length > 1 ? availableHeight / (companies.length - 1) : availableHeight;

  // Calculate SVG height
  const svgHeight = maxSvgHeight;

  const yScale = (index) => {
    return paddingTop + index * dataPointDistance;
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <svg ref={svgRef} width={svgWidth} height={svgHeight + paddingBottom}>
        <text x="50%" y="40" textAnchor="middle" fontSize="24" fill="black">{title}</text>

        {/* Vertical line */}
        <line
          x1={svgWidth / 2}
          y1={paddingTop}
          x2={svgWidth / 2}
          y2={svgHeight + paddingTop / 2}
          stroke={lineColor}
          strokeWidth="2"
        />

        {/* Companies */}
        {companies.map((company, index) => (
          <g key={index}>
            {/* Timeline dot */}
            <circle
              cx={svgWidth / 2}
              cy={yScale(index) + paddingTop / 2}
              r="6"
              fill={color}
            />

            {/* Company name and dates */}
            <text
              x={index % 2 === 0 ? svgWidth / 2 - 15 : svgWidth / 2 + 15}
              y={yScale(index) + paddingTop / 2}
              textAnchor={index % 2 === 0 ? "end" : "start"}
              alignmentBaseline="middle"
              fill={textColor}
              fontSize="12"
            >
              {company.name}
            </text>
            <text
              x={index % 2 === 0 ? svgWidth / 2 - 15 : svgWidth / 2 + 15}
              y={yScale(index) + 20 + paddingTop / 2}
              textAnchor={index % 2 === 0 ? "end" : "start"}
              alignmentBaseline="middle"
              fill={textColor}
              fontSize="10"
            >
              {`${formatDate(company.startDate)} - ${formatDate(company.endDate)}`}
            </text>

            {/* Connector line */}
            {index < companies.length - 1 && (
              <line
                x1={svgWidth / 2}
                y1={yScale(index) + 6 + paddingTop / 2}
                x2={svgWidth / 2}
                y2={yScale(index + 1) - 6 + paddingTop / 2}
                stroke={lineColor}
                strokeWidth="2"
              />
            )}
          </g>
        ))}
      </svg>
    </div>
  );
};

export default VerticalTimeline;