import React, { useState, useRef, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import './App.css';
import TimelineGraph from './TimelineGraph';
import VerticalTimeline from './VerticalTimeline';
import AuthForm from './AuthForm';
import Pricing from './Pricing';
import WaitlistForm from './WaitlistForm';


if (!process.env.REACT_APP_SUPABASE_URL || !process.env.REACT_APP_SUPABASE_ANON_KEY) {
  console.error('Missing Supabase environment variables');
}

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

function App() {
  const svgRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 80 }, (_, i) => currentYear - i);
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const [companies, setCompanies] = useState([]);
  const [startMonth, setStartMonth] = useState('');
  const [startYear, setStartYear] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [endYear, setEndYear] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [title, setTitle] = useState('');
  const [graphColor, setGraphColor] = useState('#dc8085');
  const [colorInput, setColorInput] = useState('#dc8085');
  const [activeView, setActiveView] = useState('horizontal');

  const [session, setSession] = useState(null);
  const [showAuthForm, setShowAuthForm] = useState(false);
  const [showUserPanel, setShowUserPanel] = useState(false);
  const [activeTab, setActiveTab] = useState('Home');
  // const [monthlyCredits, setMonthlyCredits] = useState(null);
  // const [floatCredits, setFloatCredits] = useState(null);
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showUserPanel && !event.target.closest('.user-avatar-container')) {
        setShowUserPanel(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showUserPanel]);

  // useEffect(() => {
  //   const fetchMonthlyCredits = async () => {
  //     if (session && session.user) {
  //       const { data, error } = await supabase
  //         .from('profiles')
  //         .select('monthly_credits')
  //         .eq('id', session.user.id)
  //         .limit(1)
  //         .single()

  //       if (error) {
  //         console.error('Error fetching monthly credits:', error);
  //       } else {
  //         setMonthlyCredits(data ? data.monthly_credits : null);
  //       }
  //     }
  //   };

  //   fetchMonthlyCredits();
  // }, [session]);

  // useEffect(() => {
  //   const fetchFloatCredits = async () => {

  //     if (session && session.user) {
  //       const { data, error } = await supabase
  //         .from('credits')
  //         .select('num_credits')
  //         .eq('user_id', session.user.id)
  //         .limit(1)
  //         .single()

  //       if (error) {
  //         console.error('Error fetching monthly credits:', error);
  //       } else {
  //         setFloatCredits(data ? data.num_credits : null);
  //       }
  //     }
  //   };

  //   fetchFloatCredits();
  // }, [session]);

  const addCompany = () => {
    if (startMonth && startYear && endMonth && endYear && companyName) {
      const newCompany = {
        name: companyName,
        startDate: new Date(parseInt(startYear), parseInt(startMonth) - 1),
        endDate: new Date(parseInt(endYear), parseInt(endMonth) - 1)
      };
      setCompanies(prevCompanies => [...prevCompanies, newCompany].sort((a, b) => a.startDate - b.startDate));
      setStartMonth('');
      setStartYear('');
      setEndMonth('');
      setEndYear('');
      setCompanyName('');
    }
  };

  const downloadPNG = () => {
    const svg = svgRef.current;
    if (!svg) {
      console.error('SVG element not found');
      return;
    }

    // Create a copy of the SVG node
    const svgCopy = svg.cloneNode(true);

    // Set a white background
    const whiteBackground = document.createElementNS("http://www.w3.org/2000/svg", "rect");
    whiteBackground.setAttribute("width", "100%");
    whiteBackground.setAttribute("height", "100%");
    whiteBackground.setAttribute("fill", "white");
    svgCopy.insertBefore(whiteBackground, svgCopy.firstChild);

    const svgData = new XMLSerializer().serializeToString(svgCopy);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = () => {
      canvas.width = svg.width.baseVal.value;
      canvas.height = svg.height.baseVal.value;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "timeline_graph.png";
      downloadLink.href = pngFile;
      downloadLink.click();
    };

    img.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(svgData)));
  };

  const handleColorSubmit = (e) => {
    e.preventDefault();
    if (/^#[0-9A-F]{6}$/i.test(colorInput)) {
      setGraphColor(colorInput);
    } else {
      alert('Please enter a valid hex color code (e.g., #FF0000)');
    }
  };

  const handleAuth = () => {
    if (!session) {
      setShowAuthForm(true);
    } else {
      console.log("Handle Auth clicked but pop up not shown");
    }
  };

  const handleGenerateFromLinkedIn = () => {
    if (!session) {
      setShowAuthForm(true);
    } else {
      // Implement LinkedIn generation logic here
      setShowWaitlistForm(true);
    }
  };

  const handleGenerateFromResume = () => {
    // const { data: { user } } = await supabase.auth.getUser()
    // console.log(user);
    if (!session) {
      setShowAuthForm(true);
    } else {
      // Implement LinkedIn generation logic here
      setShowWaitlistForm(true);
    }
  };

  const toggleUserPanel = () => {
    setShowUserPanel(!showUserPanel);
  };

  useEffect(() => {
    const checkSuccessUrl = () => {
      if (window.location.pathname === '/success') {
        console.log("Success URL accessed:", window.location);
        window.location.href = '/';
      }
    };

    checkSuccessUrl();
  }, []);

  useEffect(() => {
    const checkCancelUrl = () => {
      if (window.location.pathname === '/cancel') {
        console.log("Cancel URL accessed:", window.location);
        window.location.href = '/';
      }
    };

    checkCancelUrl();
  }, []);

  return (
    <div className="app-container">
      <nav className="navbar">
        <div className="nav-tabs">
          <button
            className={`nav-tab ${activeTab === 'Home' ? 'active' : ''}`}
            onClick={() => setActiveTab('Home')}
          >
            Home
          </button>
          <button
            className={`nav-tab ${activeTab === 'Pricing' ? 'active' : ''}`}
            onClick={() => setActiveTab('Pricing')}
          >
            Pricing
          </button>
        </div>
        {session && (
          <div className="user-avatar-container">
            <button className="avatar-button" onClick={toggleUserPanel}>
              {session.user.email.charAt(0).toUpperCase()}
            </button>
            {showUserPanel && (
              <div className="user-panel">
                {/* <p>Monthly credit: {monthlyCredits !== null ? monthlyCredits : 'Loading...'}</p>
                <p>Floating credit: {floatCredits !== null ? floatCredits : 'Loading...'}</p> */}
                <button onClick={() => supabase.auth.signOut()}>Log Out</button>
              </div>
            )}
          </div>
        )}
      </nav>

      {activeTab === 'Home' && (
        <div className="container">
          <div className="box">
            <div className="left-section">
              <h2>Career Timeline Graph Generator</h2>
              <div className="input-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="input-group">
                <label htmlFor="start-date">Start Date</label>
                <div className="date-input">
                  <select id="start-month" value={startMonth} onChange={(e) => setStartMonth(e.target.value)}>
                    <option value="">Month</option>
                    {months.map((month, index) => (
                      <option key={index} value={index + 1}>{month}</option>
                    ))}
                  </select>
                  <select id="start-year" value={startYear} onChange={(e) => setStartYear(e.target.value)}>
                    <option value="">Year</option>
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input-group">
                <label htmlFor="end-date">End Date</label>
                <div className="date-input">
                  <select id="end-month" value={endMonth} onChange={(e) => setEndMonth(e.target.value)}>
                    <option value="">Month</option>
                    {months.map((month, index) => (
                      <option key={index} value={index + 1}>{month}</option>
                    ))}
                  </select>
                  <select id="end-year" value={endYear} onChange={(e) => setEndYear(e.target.value)}>
                    <option value="">Year</option>
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="input-group">
                <label htmlFor="company">Add Company</label>
                <input type="text" id="company" name="company" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
              </div>
              <button className="add-button" onClick={addCompany}>Add</button>
              <div className="spacer"></div>
              <button className="resume-button" onClick={handleGenerateFromResume}>
                Generate from Resume ✨
              </button>
              <button className="linkedin-button" onClick={handleGenerateFromLinkedIn}>
                Generate from LinkedIn ✨
              </button>
            </div>
            <div className="right-section">

              <div className="view-switch">
                <button
                  className={`switch-button ${activeView === 'horizontal' ? 'active' : ''}`}
                  onClick={() => setActiveView('horizontal')}
                >
                  Horizontal
                </button>
                <button
                  className={`switch-button ${activeView === 'vertical' ? 'active' : ''}`}
                  onClick={() => setActiveView('vertical')}
                >
                  Vertical
                </button>
              </div>
              <div className="timeline-container">
                {activeView === 'horizontal' ? (
                  <TimelineGraph companies={companies} color={graphColor} svgRef={svgRef} title={title} />
                ) : (
                  <VerticalTimeline companies={companies} color={graphColor} svgRef={svgRef} title={title} />
                )}
              </div>
              <div className="bottom-controls">
                <div className="color-control">
                  <label htmlFor="color-input">Color:</label>
                  <input
                    type="text"
                    id="color-input"
                    value={colorInput}
                    onChange={(e) => setColorInput(e.target.value)}
                    placeholder="#000000"
                  />
                  <button onClick={handleColorSubmit}>Submit</button>
                </div>
                <button
                  className="download-button"
                  onClick={downloadPNG}
                  disabled={companies.length === 0}
                >
                  Download PNG
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === 'Pricing' && (
        <Pricing session={session} handleAuth={handleAuth} supabase={supabase} />
      )}

      {showAuthForm ? (
        <AuthForm
          supabase={supabase}
          onClose={() => setShowAuthForm(false)}
          onSuccess={() => setShowAuthForm(false)}
        />
      ) : (
        <></>
      )}

      {showWaitlistForm ? (
        <WaitlistForm
          supabase={supabase}
          onClose={() => setShowWaitlistForm(false)}
          onSuccess={() => setShowWaitlistForm(false)}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default App;
