import React, { useState } from 'react';
import './WaitlistForm.css';

const WaitlistForm = ({ supabase, onClose, onSuccess }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState(""); // New state for name

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      console.error('Invalid email format');
      return; // Prevent submission if email is invalid
    }

    // New code to insert email into Supabase
    const { error } = await supabase
      .from('waitlist')
      .insert([{ name: String(name), email: String(email) }]);

    if (error) {
      console.error('Error inserting email:', error);
      return; // Handle error appropriately
    }

    onSuccess();
  };

  return (
    <div className="waitlist-form-overlay">
      <div className="waitlist-form">
        <h2>Feature Coming Soon</h2>

        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="name">Be the first to know when we launch a new feature</label> {/* New label for name */}
            <input
              type="text"
              value={name} // Bind name state
              onChange={(e) => setName(e.target.value)} // Update name state
              placeholder="Enter your name" // Placeholder for name
              required
            />
          </div>

          <div className="input-group">
            {/* <label htmlFor="email"></label> */}
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>

          <button type="submit" className="waitlist-button">Join Waitlist</button>
          <button type="button" onClick={onClose} className="cancel-button">Cancel</button>
        </form>
      </div>

    </div>
  );
};

export default WaitlistForm;